<h2 mat-dialog-title class="mb-2">Goodie log for user: {{ data.username }}</h2>

<mat-dialog-content>
  <div class="flex h-screen" *ngIf="showSpinner">
    <div class="m-auto">
      <mat-progress-spinner style="margin: 0 auto" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  <div class="flex flex-wrap items-center mb-2">
    <div class="flex flex-col sm:flex-row items-center justify-start w-full max-w-xs sm:max-w-none">
      <button mat-flat-button [color]="'primary'" (click)="addUserGoodie()">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add Goodie</span>
      </button>
    </div>
  </div>

  <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between pb-3">
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </div>

  <div class="flex flex-auto overflow-hidden">
    <!-- users list -->
    <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden">
      <ng-container *ngIf="goodiesCount > 0; else noData">
        <!-- Table wrapper -->
        <div class="overflow-x-auto sm:overflow-y-auto" cdkScrollable>
          <!-- Table -->
          <table
            class="w-full min-w-320 table-fixed bg-transparent"
            [ngClass]="{ 'pointer-events-none': isLoading }"
            mat-table
            matSort
            [dataSource]="goodies$"
            [trackBy]="trackByFn"
            [matSortActive]="'updatedAt'"
            [matSortDirection]="'desc'"
          >
            <!-- earnedFor -->
            <ng-container matColumnDef="earnedFor">
              <th class="bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell *matHeaderCellDef mat-sort-header>
                Earned for
              </th>
              <td class="pr-4 break-words" mat-cell *matCellDef="let row">
                {{ row.earnedFor }}
              </td>
            </ng-container>

            <!-- coinsEarned -->
            <ng-container matColumnDef="coinsEarned">
              <th class="bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell *matHeaderCellDef mat-sort-header>
                Goodies
              </th>
              <td class="pr-4 break-words" mat-cell *matCellDef="let row">
                {{ row.coinsEarned }}
              </td>
            </ng-container>

            <!-- earnedForId-->
            <ng-container matColumnDef="earnedForId">
              <th class="bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell *matHeaderCellDef mat-sort-header>
                Earned for Item
              </th>
              <td class="pr-4 break-words" mat-cell *matCellDef="let row">
                <a
                  class="font-medium hover:underline text-primary-500 cursor-pointer"
                  (click)="showPostDetails(row.earnedForId?._id)"
                  *ngIf="row.earnedFor === 'newPost' || 'reaction'"
                  >{{ row.earnedForId?.dishName }}
                </a>
                <span class="font-medium" *ngIf="row.earnedFor === 'newReferral'"
                  >{{ row.earnedForId?.username }}
                </span>
                <span
                  class="font-medium"
                  *ngIf="row.earnedFor === 'addRestaurant' || 'restaurantRatings' || 'restaurantImageEdit'"
                  >{{ row.earnedForId?.name }}
                </span>
              </td>
            </ng-container>

            <!-- reason-->
            <ng-container matColumnDef="reason">
              <th class="bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell *matHeaderCellDef mat-sort-header>
                Reason
              </th>
              <td class="pr-4 break-words" mat-cell *matCellDef="let row">
                {{ row.reason }}
              </td>
            </ng-container>

            <!-- createdAt -->
            <ng-container matColumnDef="createdAt">
              <th class="bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell *matHeaderCellDef mat-sort-header>
                Created At
              </th>
              <td class="pr-4 overflow-ellipsis" mat-cell *matCellDef="let row">
                {{ row.createdAt | date: 'medium' }}
              </td>
            </ng-container>

            <!-- updatedAt -->
            <ng-container matColumnDef="updatedAt">
              <th class="bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell *matHeaderCellDef mat-sort-header>
                Updated At
              </th>
              <td class="pr-4 overflow-ellipsis" mat-cell *matCellDef="let row">
                {{ row.updatedAt | date: 'medium' }}
              </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
              <th class="bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell *matHeaderCellDef disableClear>
                Actions
              </th>
              <td class="pr-2 truncate" mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu_button">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="onEdit(row)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="deleteItem(row)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr class="shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr class="h-18 hover:bg-hover" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </ng-container>

      <ng-template #noData>
        <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">There is no data!</div>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <mat-paginator
    class="sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent mr-3"
    [length]="goodiesCount"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [showFirstLastButtons]="true"
    *ngIf="goodiesCount > 0"
  >
  </mat-paginator>

  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>
